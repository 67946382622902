export const isEmail = (value: string) => {
  if (value.length > 255) {
    return 'Емейл не повинен перевищувати 255 символів'
  } else if (true) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!regex.test(value)) {
      return 'Некоректний емейл'
    }
  }
  return true
}

export const isPassword = (value: string) => {
  const regexOneUppercase = /^(?=.*[A-Z]).+$/
  const regexOneLowercase = /^(?=.*[a-z]).+$/
  const regexOneNumber = /^(?=.*[0-9]).+$/

  if (value.length < 8) {
    return 'Не менше 8 символів'
  } else if (!regexOneUppercase.test(value)) {
    return 'Пароль повинен містити хоча б одну велику літеру'
  } else if (!regexOneLowercase.test(value)) {
    return 'Пароль повинен містити хоча б одну маленьку літеру'
  } else if (!regexOneNumber.test(value)) {
    return 'Пароль повинен містити хоча б одну цифру'
  }
  return true
}

export const isPhoneNumb = (value: string) => {
  const regex = /^\+?[\d\s\(\)\-]+$/

  if (value.length < 8) {
    return 'Мінімум 8 символів'
  } else if (!regex.test(value)) {
    return 'Номер телефону не коректний'
  }
  return true
}

export const isUserName = (userName: string) => {
  const regex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ'ʼ ]+$/
  const isMinLength = userName.replace(/\s/g, '').length >= 2
  const isMaxLength = userName.replace(/\s/g, '').length <= 25

  if (!isMinLength) {
    return "Ім'я повинно містити хоча б 2 символи"
  } else if (!isMaxLength) {
    return "Ім'я повинно містити не більше 25 символів"
  } else if (!regex.test(userName)) {
    return "Некоректне ім'я"
  }

  return true
}

export const runValidation = (
  object: object,
  rules: { [key: string]: Function },
) => {
  const errors: any = {}

  for (const iterator in object) {
    const rule = rules[iterator]
    const value = object[iterator]

    if (typeof rule === 'function') {
      const result = rule(value)
      if (result !== true) {
        return {
          status: 'error',
          errors: {
            [iterator]: result,
          },
        }
      }
    } else {
      console.warn(`No validation rule found for field: ${iterator}`)
    }
  }

  return {
    status: 'success',
    errors: {},
  }
}
