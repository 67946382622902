import { useState } from 'react'
import { ReactSVG } from 'react-svg'

import { useDebounce } from '@hooks/useDebounce'

import styles from './Input.module.sass'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string
  name?: string
  id?: string //for label
  placeholder: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  className?: string
  value: string
  isValid: boolean
  required?: boolean
  errorMessage?: string
  min?: number
  step?: string
}

const Input = ({
  type = 'text',
  name,
  id,
  placeholder,
  onChange,
  onBlur,
  className,
  value,
  isValid,
  required = false,
  errorMessage,
  min,
  step,
  ...props
}: IProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const customClassName = styles[className] || ''

  //for showing error only if input value is not empty
  const debauncedValue = useDebounce(value !== '', 1000)
  const isValidDebaounced = debauncedValue && !isValid

  const isValidStyles = !isValidDebaounced ? styles.input : styles.invalid

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState)
  }

  return (
    <div className={styles.inputContainer}>
      <input
        id={id || name}
        style={
          type === 'password' && isPasswordVisible
            ? { paddingRight: '40px' }
            : {}
        }
        name={name}
        className={`${customClassName} ${isValidStyles}`}
        value={value}
        step={step}
        type={
          type === 'password' && isPasswordVisible
            ? 'text'
            : type === 'password'
              ? 'password'
              : type
        }
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        required={required}
        min={min}
        {...props}
      />
      {type === 'password' && (
        <div className={styles.eyeContainer} onClick={togglePasswordVisibility}>
          {
            <ReactSVG
              src={
                isPasswordVisible
                  ? '/icons/eye-password.svg'
                  : '/icons/eye-password-closed.svg'
              }
            />
          }
        </div>
      )}
      {isValidDebaounced && (
        <div className={styles.errorMessage}>
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  )
}

export default Input
