import {
  rulesRegisterForm,
  rulesLoginForm,
  rulesFindRacesForm,
  rulesOrderATripForm,
  rulesFindRoutesForm,
  rulesForgotPwForm,
  rulesResetPwForm,
  rulesEditUserForm,
  rulesContactConsultantForm,
  rulesCalculateDeliveryPriceForm,
} from './registerFormValidation'

import { runValidation } from './utils/index'

export const makeValidation = (objectForValidation: object, type: string) => {
  switch (type) {
    case 'registerForm':
      return runValidation(objectForValidation, rulesRegisterForm)
    case 'loginForm':
      return runValidation(objectForValidation, rulesLoginForm)
    case 'findRacesForm':
      return runValidation(objectForValidation, rulesFindRacesForm)
    case 'orderATripForm':
      return runValidation(objectForValidation, rulesOrderATripForm)
    case 'findRoutesForm':
      return runValidation(objectForValidation, rulesFindRoutesForm)
    case 'forgotPwForm':
      return runValidation(objectForValidation, rulesForgotPwForm)
    case 'resetPwForm':
      return runValidation(objectForValidation, rulesResetPwForm)
    case 'editUserForm':
      return runValidation(objectForValidation, rulesEditUserForm)
    case 'contactConsultantForm':
      return runValidation(objectForValidation, rulesContactConsultantForm)
    case 'calculateDeliveryPriceForm':
      return runValidation(objectForValidation, rulesCalculateDeliveryPriceForm)
  }
}
