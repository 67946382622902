import { IDateRange } from '@interfaces/general'
import { isEmail, isPassword, isPhoneNumb, isUserName } from './utils'

export const rulesRegisterForm = {
  firstName: (value: string) => isUserName(value),
  lastName: (value: string) => {
    if (value.length < 2) {
      return 'Прізвище повинно містити хоча б 2 символи'
    }
    return true
  },
  middleName: (value: string) => {
    if (value && value.length < 2) {
      return 'По батькові повинно містити хоча б 2 символи'
    }
    return true
  },
  email: (value: string) => isEmail(value),
  phoneNumber: (value: string) => isPhoneNumb(value),
  password: (value: string) => isPassword(value),
  passwordConfirm: (value: string) => {
    if (value.length < 8) {
      return 'Не менше 8 символів'
    }
    return true
  },
  birthDate: (value: string) => {
    if (!value) {
      return "Дата народження обов'язкова"
    }
    return true
  },
}

export const rulesLoginForm = {
  email: (value: string) => isEmail(value),
  password: (value: string) => isPassword(value), //? shall I validate password on login?
  // password: () => true,
}

export const rulesFindRacesForm = {
  fromLocationCountry: (value: string) => {
    if (!value) {
      return "Країна обов'язкова"
    }
    return true
  },
  fromLocationCity: (value: string) => {
    if (!value) {
      return "Місто обов'язкове"
    }
    return true
  },
  departureDate: (value: IDateRange | null) => {
    if (!value) {
      return "Дата обов'язкова"
    }
    return true
  },
  backTripDate: (value: IDateRange | null) => {
    if (!value) {
      return "Дата обов'язкова"
    }
    return true
  },
  toLocationCountry: (value: string) => {
    if (!value) {
      return "Країна обов'язкова"
    }
    return true
  },
  toLocationCity: (value: string) => {
    if (!value) {
      return "Місто обов'язкове"
    }
    return true
  },
}

export const rulesOrderATripForm = {
  firstName: (value: string) => {
    if (!value) {
      return "Ім'я обов'язкове"
    } else if (value.length < 2) {
      return "Ім'я повинно містити хоча б 2 символи"
    }
    return true
  },
  lastName: (value: string) => {
    if (!value) {
      return "Прізвище обов'язкове"
    } else if (value.length < 2) {
      return 'Прізвище повинно містити хоча б 2 символи'
    }
    return true
  },
  phoneNumber: (value: string) => {
    if (!value) {
      return "Номер телефону обов'язковий"
    } else if (value.length < 6) {
      return 'Номер телефону повинен містити хоча б 6 символів'
    }
    return true
  },
  connectionType: (value: string) => {
    if (!value) {
      return "Спосіб зв'язку обов'язковий"
    }
    return true
  },
  baggageAmount: (value: number) => {
    if (!value) {
      return "Кількість багажу обов'язкова"
    }
    return true
  },
  departureType: (value: string) => {
    if (!value) {
      return "Тип відправлення обов'язковий"
    }
    return true
  },
  departurePlace: (value: string) => {
    if (!value) {
      return "Місце відправлення обов'язкове"
    }
    return true
  },
  arrivalPlaceType: (value: string) => {
    if (!value) {
      return "Тип прибуття обов'язковий"
    }
    return true
  },
  arrivalPlace: (value: string) => {
    if (!value) {
      return "Місце прибуття обов'язкове"
    }
    return true
  },
  adultPassengerAmount: (value: number) => {
    if (!value) {
      return "Кількість дорослих обов'язкова"
    }
    return true
  },
  youngPassengerAmount: (value: number) => {
    if (!value) {
      return "Кількість дітей обов'язкова"
    }
    return true
  },
  note: (value: string) => {
    if (value && value.length < 2) {
      return 'Примітка повинна містити хоча б 2 символи'
    }
    return true
  },
}

export const rulesFindRoutesForm = {
  arrival_country_id: (value: string) => {
    if (!value) {
      return 'Країна обовязкова'
    }
    return true
  },
  departure_country_id: (value: string) => {
    if (!value) {
      return 'Країна обовязкова'
    }
    return true
  },
  departure_city_id: (value: string) => {
    if (!value) {
      return 'Місто обовязкове'
    }
    return true
  },
  arrival_city_id: (value: string) => {
    if (!value) {
      return 'Місто обовязкове'
    }
    return true
  },
  departure_time: (value: string) => {
    if (!value) {
      return 'Дата обовязкова'
    }
    return true
  },
  return_time: (value: string) => {
    if (!value) {
      return 'Дата обовязкова'
    }
    return true
  },
}

export const rulesForgotPwForm = {
  email: (value: string) => isEmail(value),
}

export const rulesResetPwForm = {
  password: (value: string) => isPassword(value),
  passwordConfirm: (value: string) => isPassword(value),
}

export const rulesEditUserForm = {
  firstName: (value: string) => isUserName(value),
  lastName: (value: string) => {
    if (value.length < 2) {
      return 'Прізвище повинно містити хоча б 2 символи'
    }
    return true
  },
  middleName: (value: string) => {
    if (value && value.length < 2) {
      return 'По батькові повинно містити хоча б 2 символи'
    }
    return true
  },
  email: (value: string) => isEmail(value),
  phoneNumber: (value: string) => isPhoneNumb(value),
}

export const rulesContactConsultantForm = {
  name: (value: string) => {
    if (value.length < 2) {
      return 'Ім`я повинно містити хоча б 2 символи'
    }
    return true
  },
  phoneNumber: (value: string) => isPhoneNumb(value),
}

export const rulesCalculateDeliveryPriceForm = {
  fromLocationCountry: (value: string) => {
    if (!value) {
      return 'Країна обовязкова'
    }
    return true
  },
  toLocationCountry: (value: string) => {
    if (!value) {
      return 'Країна обовязкова'
    }
    return true
  },
  weight: (value: number) => {
    if (!value) {
      return 'Вага обовязкова'
    }

    if (value > 30) {
      return 'Максимальна вага 30 кг'
    }
    return true
  },
  deliveryValue: (value: string) => {
    if (!value) {
      return 'Вид доставки обовязковий'
    }
    return true
  },
  shippingValue: (value: string) => {
    if (!value) {
      return 'Вид відправлення обовязковий'
    }
    return true
  },
}
