export const getValidationErrorMessage = (error: object, key: string) => {
  if (!error[key]) {
    return ''
  }

  const errorMessage = error[key]
  
  return errorMessage as string
}

export const isValidationErrorsEmpty = (error: object) => {
  return Object.keys(error).length === 0
}

export const isAllRequiredFieldsFilled = (
  object: object,
  requiredFields: string[]
) => {}
